import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import GlobalContext from '../../context/store';

const ServicesItem = ({ title, image, link }) => (

  <div className="item">
    <div className="position-re o-hidden"> <img src={image} alt="" /> </div>
    <div className="con">
      <Link to={`/services/service/${link}`}>
        <h5>{title}</h5>
      </Link>
    </div>
  </div>
)

function Index() {
  const { services } = useContext(GlobalContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="yscott-services yscott-services-wrap">
      <div className="container">
        <div className="row margin-mobile">
          <div className="col-md-12 col-sm-12">
            <div className="yscott-text-block yscott-servicess-block">
              <div className="">
                <h2 className="yscott-servicess-title">Services</h2>
                <p>
                Dollhouse Mermaid offers a wide range of services to cater to your makeup needs. Whether you're searching for flawless makeup applications, eager to learn the latest makeup techniques, or simply pamper yourself through our luxury glams Dollhouse Mermaid is glad to work with you to bring your vision to life!  
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {services.length > 0 && services.map((s, i) => (
            <div className="col-md-6">
              <ServicesItem title={s.name} image={s.cover} link={s.slug} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Index