'use client';

import { createContext } from 'react';

const GlobalContext = createContext({
  company: {},
  setcompany: () => {},

  services: [],
  setservices: () => {},

  showBooking: false,
  setShowBooking: () => {},
});

export default GlobalContext;