import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';

function LandingSlider() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ paddingTop: '0 !important' }} className="yscott-homepage-section">
      <div className="container">
        <div style={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          margin: '0 auto',
          // textAlign: 'center',
          zIndex: 100,
        }}>
          
          <Swiper
            modules={[Autoplay]}
            allowSlideNext={true}
            allowSlidePrev={true}
            pagination={false}
            centeredSlides={true}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            style={{ width: '100%', height: '100%', overflow: 'hidden' }} // Ensure full width and correct overflow
            className="swiper-container"
          >
            <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/2.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide>
            <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/3.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide>
            {/* <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/5.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide> */}
            <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/6.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide>
            {/* <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/7.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide> */}
            <SwiperSlide style={{ width: '100%', height: '100%' }}>
              <div style={{
                backgroundImage: `url('https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/8.jpeg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: windowWidth > 768 ? 'left' : 'center',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}></div>
            </SwiperSlide>
          </Swiper>

          <div style={{zIndex:999}} className="yscott-view-on-mobile">
            <h1>Viki Diaz</h1>
            <p style={{ textAlign: 'center' }} className="yscott-p-subtitle">Makeup Artist</p>
          </div>

          <div style={{
            position: 'absolute',
            top: windowWidth > 768 ? '50%' : '90%',
            left: windowWidth > 768 ? '85%' : '45%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            width: windowWidth > 768 ? '75%' : '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}>
            <div className="yscott-content-absolute yscott-content-absolute-right">
              <div className="yscott-not-view-on-mobile">
                <h1>Viki Diaz</h1>
                <p style={{ textAlign: 'center' }} className="yscott-p-subtitle">Makeup Artist</p>
              </div>
              <p style={{ width: windowWidth > 749 ? '75%' : '90%', marginLeft: '11%'}} className="text yscott-text-home">Hello,  Welcome to Dollhouse Mermaid! Where glamour has no boundaries and true beauty radiates from within!</p>
            </div>
            <div style={{ marginLeft: windowWidth > 749 ? '-50%' : '10%', backgroundColor: windowWidth > 749 ? 'transparent' :  '#edd5cf', marginTop: windowWidth > 768 ? 0 : -20 }} className="yscott-color-button">
              <div className="yscott-button-link"> <Link to={'/about'}> About Artist</Link> <span className="yscott-button-block yscott-button-block-grey"></span> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingSlider