import React, { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Get } from '../api/server';
import GlobalContext from '../context/store';
import moment from 'moment';

function Yelp() {
  const { company } = useContext(GlobalContext);
  const [reviews, setReviews] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

  useEffect(() => {
    async function getServices() {
      if (!company?._id) return;
      const response = await Get(`/yelps/dollhousemermaid-cupertino-2`);
      console.log(response);
      setReviews(response?.reviews || []);
    }
    getServices();
  }, [company])

  return reviews.length > 0 ? (
    <div style={{backgroundColor: '#ffffff'}} className="margin-top-50 mb-20">
      <div className="container">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="yscott-details-block">
                <h3 className="yscott-details-title">Yelp Reviews</h3>
              </div>
            </div>
          </div>
          <div style={{margin: '30px 0 !important'}} className="col-md-12">
            <Swiper
              modules={reviews.length > 1 ? [Autoplay] : null}
              allowSlideNext={reviews.length > 1 ? true : false}
              allowSlidePrev={reviews.length > 1 ? true : false}
              pagination={false}
              slidesPerView={windowWidth > 430 ? 3 : 1}
              centeredSlides={false}
              spaceBetween={30}
              loop={true}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              style={{ height: '100%', overFlow: 'none', objectFit: 'cover' }}
              className="swiper-container full-height w-full h-full"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <div className="comment-card">
                    <div className="profile-img">
                      <img src={review?.user.image_url} alt='' />
                        <div className="user-names">
                          <span className="name">{review?.user.name}</span>
                          <span className="title">{moment(review?.time_created).format('lll')}</span>
                        </div>
                    </div>
                    <div className="user-details">
                      <p>{review?.text}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Yelp