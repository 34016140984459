import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import AboutDescription from '../components/AboutDescription';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeader image={'https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/aboutBanner.jpeg'} title='About Viki Diaz' details={``} />

      <AboutDescription
        details={[
          `Aesthetics & Art enthusiast with a passion for uplifting, enlightening and bringing visions to life! My love for art and beauty began in 2007 while studying studio art and photography. I was highly intrigued by cosmetics and their ability to enhance, define and bring confidence to one’s appearance. Radically Inspired by my love for beauty, art and making others feel good. I launched my career as a freelance makeup artist with a background in aesthetics.`,
          `I am most inspired by the simplistic beauty of nature and the poise of high fashion. With my artistic background, profound skincare knowledge, versatile makeup techniques, and extensive communication, I am capable of creating a flawless face every time. Not only delivering beautiful makeup to the eye, but one that feels lightweight, effortless and comfortable. I truly enjoy the process of customizing a makeup look to enhance natural beauty.`,
          `Specializing in soft & full glam makeup applications for all of life's special moments! When you book Dollhouse Mermaid you can expect: makeup styling, luxurious skin care, prestigious beauty brands, harmonious blending, impecable detailing, and so much more. You are sure to look & feel your best at your next special occasion! My mission is to help women be the best versions of themselves. With over 16 years of experience, I have refined, cultivated and dedicated my life to perfecting my craft, treating it as my purpose in life.`,
          `I’ve had the pleasure of working under professional environments such as Univision network television as a live "beauty segment" host, artist for JLO's dancers, Nina Sky, WWE’s Carmela, Bayley & Mercedes Mone. I’ve hosted beauty seminars, Mac Cosmetics Alumni & Impact team member, Lead makeup artist for Editorial photoshoots, Video shoots, Wedding ceremonies & receptions.`,
          `"It is a blessing to meet and glam so many amazing women!"`
        ]}
        title="Who am I?"
        title2="My Artists"
      />
    </>
  )
}

export default About