import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GlobalContext from '../../context/store';
import { useNavigate, useParams } from 'react-router-dom';
import { Get } from '../../api/server';
import CountdownTimer from '../../components/CountdownTimer';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from 'moment';

function Conformation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { company } = useContext(GlobalContext);
  const [booking, setBooking] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const response = await Get(`/bookings/confirmation/${id}`);
        console.log(response)
        setBooking(response);
      } catch (error) {
        navigate('/');
      } 
    }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Row>
        <Col style={{ marginTop: 150 }}>
          <center>
            <h1 className="open-sans font-bold capitalize">Your booking is complete!</h1>
          </center>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <center>
            <p className="open-sans font-light">Thank you for booking with me. I look forward to seeing you soon.</p>
          </center>
        </Col>

      </Row>
      <Container className="py-5">
        <Row className="text-center mb-4">
          <Col>
            <h4 className="open-sans font-light">Conformation #{booking?.confirmation}</h4>
            <CountdownTimer eventDate={booking?.startTime} />
          </Col>
        </Row>
        <Row className="text-center mb-4">

        </Row>

        <Row className="text-center mb-4">
          <Col md={2}>
            <div className="bg-red-500 text-white inline-block shadow mb-10">
              <span className="font-bold p-3 text-sm">{moment(booking?.startTime).format('MMMM')}</span>
              <span style={{width: 100}} className="block text-5xl font-bold text-gray-900 bg-gray-50 py-3">{moment(booking?.startTime).format('D')}</span>
            </div>
          </Col>
          <Col md={8}>
            <p className="font-bold">SAVE THE DATE: {moment(booking?.startTime).format('dddd')} {moment(booking?.startTime).format('MMMM')} {moment(booking?.startTime).format('Do')} • {moment(booking?.startTime).format('h:mm a')} Pacific Time</p>
            <p>Thank you for scheduling your appointment with us. We're excited to see you soon!

              If you need to cancel or reschedule your appointment, please contact us directly by email at {company?.email}. We'll be happy to assist you with any changes.

              An email confirmation has been sent to you. {!booking?.isTravling && "The address for your appointment will be sent to you shortly."} </p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col md={12}>
            <p className="open-sans font-light text-lg">Add to Calendar</p>
            <center>
              <AddToCalendarButton
                name={`${company?.name} - ${booking?.service} Appointment`}
                options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                location={booking?.address}
                startDate={moment(booking?.startTime).format('YYYY-MM-DD')}
                endDate={moment(booking?.endTime).format('YYYY-MM-DD')}
                startTime={moment(booking?.startTime).format('HH:MM')}
                endTime={moment(booking?.endTime).format('HH:MM')}
                timeZone="America/Los_Angeles"
                buttonsList
                hideTextLabelButton
                buttonStyle="round"
              ></AddToCalendarButton>
            </center>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Conformation