import React from 'react'

function AboutDescription({ title, details, title2 }) {
  return (
    <div class="yscott-description-section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            {details.map((detail, index) => (
              <p key={index}>{detail}</p>
            ))}
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="yscott-artist-wrap">
              <div class="img-zoomer"> <img src={'https://dollhousemermaid.s3.us-west-1.amazonaws.com/img/aboutMe.jpeg'} class="img-fluid" alt="" /></div>
              <div class="yscott-artist-title">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutDescription