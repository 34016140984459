export default function parseFullName(fullName) {
  // Define suffixes to look for
  const suffixes = ["Jr.", "jr", "JR", "III", "II", "IV", "V", "iii", "ii", "iv", "v", "Sr.", "sr", "SR"];
  
  // Initialize components
  let firstName = null;
  let middleName = null;
  let lastName = null;
  let suffix = null;

  // Split the name into parts
  const nameParts = fullName.split(' ');

  // Check if the last part is a suffix
  if (suffixes.includes(nameParts[nameParts.length - 1])) {
    suffix = nameParts.pop();
  }

  // Assign the first name (first element)
  if (nameParts.length > 0) {
    firstName = nameParts.shift();
  }

  // Assign the last name (last element)
  if (nameParts.length > 0) {
    lastName = nameParts.pop();
  }

  // Remaining parts are considered middle names
  if (nameParts.length > 0) {
    middleName = nameParts.join(' ');
  }

  // Convert empty strings to null
  if (firstName === "") firstName = null;
  if (middleName === "") middleName = null;
  if (lastName === "") lastName = null;
  if (suffix === "") suffix = null;

  return {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    suffix: suffix
  };
}