import React, { useContext} from 'react';
import LOGO from '../assets/logos/logoDark.png';
import ShOPLOGO from '../assets/logos/badDoll/logoDark.png';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import SideMenu from './SideMenu';
import routes from '../routes';
import GlobalContext from '../context/store';

function NavBar({handleShow}) {
  const {setShowBooking} = useContext(GlobalContext)

  return (
    <>
      <header>
      <div className="yscott-header-navbar">
        <div className="container yscott-navbar-container">
          <div className="yscott-navigation-wrap bg-light yscott-start-header scroll-on">
            <div className="container">
              <Row>
                <Col sm={12}>
                  <nav className="navbar navbar-expand-md navbar-light gap-20">
                    <Link className="navbar-brand" to="/"> <img src={LOGO} alt="Yvonne Scott" /> </Link>
                    <div class="block md:hidden">
                      <SideMenu />
                    </div>

                    <div className='w-full flex items-center gap-4 hidden md:flex'>

                      {routes.map((route, key) => {

                        return route.show ? (
                          <>
                            {!route.modal ? (
                              <Link key={key} to={route.path} className="nav-link">
                                {route.name}
                              </Link>
                            ) : (
                              <Link key={`${key}b`} to="#" onClick={() => setShowBooking(true)} className="nav-link" data-toggle="modal" data-target="#bookingModal">
                                {route.name}
                              </Link>
                            )}
                          </>

                        ) : null
                      })}
                      <a href="https://shopbaddoll.com" target="_BLANK" className="pointer" rel="noreferrer" style={{ marginTop: 13 }}>
                        <img className='shopBadLogo' src={ShOPLOGO} alt='Logo of shop' />
                      </a>
                    </div>
                  </nav>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default NavBar