import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GOLDENGATE from '../../assets/images/gallerys/gallery.png';
import { Link } from 'react-router-dom';
import { Get } from '../../api/server';
import GlobalContext from '../../context/store';

const GalleryList = () => {
  const { company } = useContext(GlobalContext);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getData() {
      if (company?._id) {
        const response = await Get(`/galleries/frontend/byCompany/${company._id}`);
        
        setPageData(response?.galleries);
      }
    }
    getData();
  }, [company]);

  const Gallery = ({ album }) => (
    <div
      style={{ border: '2px solid #edd5cf !important', height: 570 }}
      className="relative overflow-hidden  shadow-highlight flex flex-col items-center justify-end gap-2 px-6 pb-16 text-white image-gallery-card"
    >
      {/* Background Image */}
      <img width={100} height={300} alt="gate" src={album?.cover || album?.items[0] || album?.images[0].image_url} className="w-full h-full object-cover  absolute top-0 left-0 z-0" />

      {/* Gradient Overlay */}
      {/* <div className="absolute top-0 left-0 w-full h-full bg-gradient-from-b from-gray-800 to-transparent  z-10"></div> */}
      <div style={{background: 'linear-gradient(to top, white, transparent, transparent)'}} className="absolute top-0 left-0 w-full h-full   z-10"></div>


      {/* Title */}
      <h1 className="open-sans z-20 mx-auto mb-[-20px] max-w-1xl mt-30 text-base font-normal uppercase tracking-widest text-gray-300 sm:text-xl text-center font-display">
        {album.name}
      </h1>

      <img style={{ marginBottom: 0, zIndex: 10 }} alt="Event Logo" src={company?.logoDark} width={150} height={75} />

      {/* Button to go back */}
      <div style={{
        marginBottom: -30,
      }} className='z-20'>
        <Link
          className="pointer z-10  border border-white bg-white px-3 py-2 text-sm font-semibold text-black transition hover:bg-white/10 hover:text-white md:mt-4"
          to={`/gallery/${album.slug}`}
        >
          View Gallery
        </Link>
      </div>
    </div>
  )

  return (
    <>
      <Container style={{ marginTop: 130 }}>
        <Row>
          <Col md={4} className='mb-3' style={{ maxHeight: 570, marginBottom: 25 }}>
            <div
              style={{ height: 570 }}
              className="relative overflow-hidden  shadow-highlight flex flex-col items-center justify-end gap-4 px-6 pb-16 pt-64 text-white image-gallery-card"
            >
              {/* Background Image */}
              <img alt="gate" src={GOLDENGATE} className="w-full h-full object-cover  absolute top-0 left-0 z-0 mt-10 opacity-30" />

              {/* Gradient Overlay */}


              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-graay-100 to-transparent  z-10"></div>



              {/* Title */}
              <h1 className="open-sans z-20 mx-auto mb-[-30px] max-w-1xl mt-8 mt-0 text-sm font-extralight uppercase tracking-widest text-gray-300 sm:text-xl text-center font-display">
                Photo Galleries
              </h1>

              <img style={{ marginbottom: -10, zIndex: 10 }} alt="Event Logo" src={company?.logoDark} width={150} height={75} />
            </div>
          </Col>

          {pageData && Array.isArray(pageData) ? (
            <>
              {
                pageData?.map((item, key) => (
                  <Col md={4} key={key} className='mb-3' style={{ maxHeight: 570, marginBottom: 25 }}>
                    <Gallery album={item} />
                  </Col>
                ))
              }
            </>
          ) : null}
        </Row>
      </Container>
    </>
  )
}

export default GalleryList;