import React, { useContext, useEffect, useState } from 'react'
import Map from '../components/Map'
import GlobalContext from '../context/store';
import { toast } from 'react-toastify';
import { Post } from '../api/server';
import isValidEmail from '../helpers/isValidEmail';
import isValidPhoneNumber from '../helpers/is ValidPhoneNumber';

function Contact() {
  const { company } = useContext(GlobalContext);
  const [values, setValues] = useState({
    name: null,
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    subject: null,
    message: null,
  });

  useEffect(() => {
    console.log(values)
  }, [values]);

  const resetForm = () => {
    setValues({
      name: null,
      first_name: null,
      last_name: null,
      phone: null,
      email: null,
      subject: null,
      message: null,
    });
  }

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!values.name || !values.email || !values.phone || !values.subject || !values.message) {
      notify('Please fill all fields', 'warning');
      return;
    }
    
    if (!isValidEmail(values?.email)) return notify('Please type in a valid email address', 'warning');
    if (!isValidPhoneNumber(values?.phone)) return notify('Please type in a valid phone number', 'warning');

    Post('/contacts', {
      first_name: values.name,
      phone: values.phone,
      email: values.email,
      subject: values.subject,
      message: values.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="yscott-map-section">
        <div class="container">
          <div class="row">
            <div style={{ height: 500 }} class="col-md-12 col-sm-12">
              <Map hasMarker={false} zoom={9} />
            </div>
            <div class="col-md-12 col-sm-12">
              <div style={{ position: 'relative' }} className="yscott-caption">
                <h2>Contact</h2>
                <p>Looking to book something a little more special or have questions? Get in touch by sending a message below. We’ll be happy to provide availability and pricing for any Occassion & Celebration.</p>
                {/* <p>Bridal & Bridal party Services, Day Rates, Travel to Destinations, Collaborations etc.</p> */}
                <ul>
                  <li>Weddings/Bridal</li>
                  <li>Day rates </li>
                  <li>Travel to destination</li>
                  <li>Collaborations </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="yscott-contact-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
            {/* <h4 style={{fontSize:18}} class="yscott-details-title">Get in touch, Booking request/Inquiry </h4> */}
              <div class="yscott-details-block">
                <h3 class="yscott-contact-title">Email</h3>
                <p><a href={`mailto:${company?.email}`}>{company?.email}</a></p>
              </div>
              <div class="yscott-details-block">
                <h3 class="yscott-contact-title">Address</h3>
                <p>
                Bay Area, California
                </p>
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="yscott-details-block">
                <h3 style={{marginBottom: 0}} class="yscott-details-title">Get in touch</h3>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input value={values?.name} name="name" onChange={onChange} type="text" placeholder="Your Name *" />
                  </div>
                  <div class="col-md-6 form-group">
                    <input value={values?.email} name="email" onChange={onChange} type="email" placeholder="Your Email *" />
                  </div>
                  <div class="col-md-6 form-group">
                    <input value={values?.phone} name="phone" type="text" onChange={onChange} placeholder="Your Number *" />
                  </div>
                  <div class="col-md-12 form-group">
                    <input value={values?.subject} name="subject" type="text" onChange={onChange} placeholder="Subject *" />
                  </div>
                  <div class="col-md-12 form-group">
                    <textarea value={values?.message} name="message" id="message" onChange={onChange} cols="30" rows="4" placeholder="Message *" />
                  </div>
                  <div class="col-md-12 margin-top-30">
                    <div onClick={handleSubmit} class="myButn pointer" >Send Message</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact