import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Get } from '../../api/server'
import PageHeader from '../../components/PageHeader'
import GlobalContext from '../../context/store'

const PriceCard = ({ title, price, details }) => (
  <div class="yscott-other-pricing-info">
    <h5 class="title">{title} <span class="price">${price}</span></h5>
    <p>{details}</p>
  </div>
)

function Service() {
  const {setShowBooking} = useContext(GlobalContext)
  const { slug } = useParams()
  const [service, setService] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getService() {
      const response = await Get(`/bookingservices/frontend/${slug}`);
      console.log('service page',response)
      setService(response)
    }
    getService()
  }, [slug])

  const formattedText = service?.details ? service?.details.replace(/\r\n/g, '<br />') : '';

  return (
    <>
    <PageHeader title={service?.name} image={service?.cover} details={null} />
      <div class="yscott-description-section service-page-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <p dangerouslySetInnerHTML={{ __html: formattedText }} />
            </div>
            <div class="col-md-6 col-sm-12 service-page-end-div">
              { service?.variations?.map((s, i) => (
                <PriceCard key={i} title={s.name} price={s.price} details={s.details} />
              ))}
            </div>
          </div>
          <div class="row">
            <div style={{marginTop:80}} class="col-md-3 offset-md-4 margin-top-80">
              <div onClick={() => setShowBooking(true)} class="myButn pointer" >Schedule Appointment</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Service