import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import GlobalContext from '../context/store';

function Services() {
  const { services } = useContext(GlobalContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="yscott-services section-padding">
      <div className="container">
        <div className="row margin-mobile">
          <div className="col-md-12 col-sm-12">
            <div className="yscott-text-block yscott-servicess-block">
              <div className="">
                <h2 className="yscott-servicess-title">Services</h2>
                <p className="">
                Dollhouse Mermaid offers a wide range of services to cater to your makeup needs. Whether you're searching for flawless makeup applications, eager to learn the latest makeup techniques, or simply pamper yourself through our luxury glams Dollhouse Mermaid is glad to work with you to bring your vision to life!  
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">

            <Swiper
              modules={services.length > 1 ? [Autoplay] : null}
              allowSlideNext={services.length > 1 ? true : false}
              allowSlidePrev={services.length > 1 ? true : false}
              pagination={false}
              slidesPerView={windowWidth > 430 ? 3 : 1}
              centeredSlides={true}
              spaceBetween={30}
              navigation
              loop={true}
              autoplay={{ delay: 2500, disableOnInteraction: true }}
              style={{ height: '100%', overFlow: 'none', objectFit: 'cover' }}
              className="swiper-container full-height w-full h-full"
            >
              {services.map((service, index) => (
                <SwiperSlide onClick={() => navigate(`/services/service/${service?.slug}`)} key={index}>
                  <Link to={`/services/service/${service?.slug}`}>
                    <div onClick={() => navigate(`/services/service/${service?.slug}`)} className="item">

                      <div className="position-re o-hidden"> <img style={{
                        height: 200,
                        objectFit: 'cover'
                      }} src={service?.cover} alt="" /> </div>
                      <div className="con">
                        <h5>{service?.name}</h5>
                      </div>

                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Services