import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import GOLDENGATE from '../../assets/images/gallerys/gallery.png';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { Link, useParams } from 'react-router-dom';
import { Get } from '../../api/server';
import GlobalContext from '../../context/store';

const Gallery = () => {
  const { company } = useContext(GlobalContext);
  const { slug } = useParams();
  const [pageData, setPageData] = useState();
  const [images, setImages] = useState([]);


  useEffect(() => {
    async function fetchData() {
      const response = await Get(`/galleries/byslug/${slug}`);
      setPageData(response);
      if (response?.images.length > 0) {
        setImages(response?.images);
      } else {
        setImages(response?.items);
      }
    }
    fetchData();
  }, [slug]);

  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  const GalleryInfo = () => (
    <Col md={4} className='mb-3' style={{ maxHeight: 470, marginBottom: 25 }}>
      <div
        style={{ height: 470 }}
        className="relative overflow-hidden  shadow-highlight flex flex-col items-center justify-end gap-4 px-6 pb-3 pt-64 text-white image-gallery-card"
      >
        {/* Background Image */}
        <img alt="gate" src={GOLDENGATE} className="w-full h-full object-cover  absolute top-0 left-0 z-0 mt-10 opacity-30" />

        {/* Gradient Overlay */}


        {/* <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-graay-100 to-transparent  z-10 mb-0"></div> */}
        {/* Title */}
        <h1 className="open-sans z-20 mx-auto mb-[-40px] max-w-1xl mt-8 mt-10 text-sm font-extralight uppercase tracking-widest text-gray-300 sm:text-xl text-center font-display">
          {pageData?.name}
        </h1>
        <img style={{ marginbottom: 0, zIndex: 10, marginBottom: -20 }} alt="Event Logo" src={company?.logoDark} width={150} height={75} />
        <Link
          style={{ zIndex: 100 }}
          variant="outline-light"
          className=" mt-0  border border-white bg-white px-3 py-2 text-sm font-semibold text-black transition hover:bg-white/10 hover:text-white md:mt-4"
          to={'/gallery'}
        >
          View More Photos
        </Link>
      </div>
    </Col>
  )



  return (
    <>
      <Container style={{ marginTop: 130 }}>

        {(pageData?.items.length > 0 || pageData?.images.length > 0) && (
          <>

            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
              addClass='reset grid grid-cols-1 md:grid-cols-4'
              galleryClassName='reset'
              style={{ all: 'unset' }}
              selector={'.image-card-gallery'}
            >
              <Row className='gallery-only'>
                <GalleryInfo />
                {images.map((item, key) => (
                  <Col
                    md={4}

                    key={key}
                  >
                    <div
                      data-src={item?.image_url}
                      data-href={item?.image_url}
                      href={item?.image_url}
                      style={{ marginBottom: '1rem' }}
                      className="p-0 relative h-[470px] max-h-[470px]  overflow-hidden hover:border-gray-100 transition-all mb-4 image-card-gallery"
                    >
                      <img
                        alt="Club X"
                        src={item?.image_url}
                        className="w-full object-cover object-top "
                        width={800}
                        height={600}
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',

                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </LightGallery>

          </>
        )}
      </Container >
    </>
  )
}

export default Gallery;