import React, { useContext, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import routes from '../routes';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import GlobalContext from '../context/store';
import { FaInstagram } from "react-icons/fa";
import { RiTiktokFill } from "react-icons/ri";
import ShOPLOGO from '../assets/logos/badDoll/logoDark.png';

const SideMenu = () => {
  const { setShowBooking } = useContext(GlobalContext)
  const { company } = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div style={{ cursor: 'pointer', zIndex: 200 }} onClick={handleShow}>
        <FiMenu size={30} color='black' />
      </div>

      <Offcanvas className="bg-slate-50 text-slate-300" style={{ width: '300px' }} show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <img
                src={company?.logoDark}
                alt='logo'
                style={{ width: '125px', height: 'auto' }}
              />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr className='border-gray-500' />
        <Offcanvas.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <ul style={{ flexGrow: 1 }}>
            {routes.map((route, key) => {

              return route.show ? (
                <>
                  {!route.modal ? (
                    <li key={key} className="pl-4 ml-md-4 mb-3 flex divide-x-1 divide-slate-500">
                      <Link
                        className='nav-link text-slate-950 divide-x-1 divide-slate-500'
                        to={route.path}
                        onClick={handleClose}
                      >
                        {route.name}
                      </Link>
                    </li>
                  ) : (
                    <li key={`${key}b`} className="pl-4 ml-md-4 mb-3 flex divide-x-1 divide-slate-500">
                      <Link
                        className='nav-link text-slate-950 divide-x-1 divide-slate-500'
                        to="#" onClick={() => {
                          setShowBooking(true);
                          handleClose();                        
                        }}
                      >
                        {route.name}
                      </Link>
                    </li>
                  )}
                </>
              ) : null
            })}

            <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
              <a href="https://shopbaddoll.com" target="_BLANK" class="nav-link pointer" rel="noreferrer">
                <img style={{ height: 30, width: 'auto', opacity: 0.8, marginTop: 2 }} src={ShOPLOGO} alt='' />
              </a>
            </li>

          </ul>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <ul className="flex justify-center gap-2">
              <Link href={'/'} className="text-sm duration-500 text-zinc-500 hover:text-zinc-300">
                <FaInstagram size={24} />
              </Link>

              <Link href={'/'} className="text-sm duration-500 text-zinc-500 hover:text-zinc-300">
                <RiTiktokFill size={24} />
              </Link>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default SideMenu