import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ eventDate }) => {
  
  const calculateTimeLeft = () => {
    const now = moment();
    const duration = moment.duration(moment(eventDate).diff(now));
    
    if (duration.asSeconds() <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [eventDate]);

  return (
    <div className="flex justify-center space-x-3 mt-3">
      <div className="bg-white p-4 rounded shadow">
        <span className="block text-2xl font-bold">{timeLeft.days}</span>
        <span className="block text-sm">days</span>
      </div>
      <div className="bg-white p-4 rounded shadow">
        <span className="block text-2xl font-bold">{timeLeft.hours}</span>
        <span className="block text-sm">hours</span>
      </div>
      <div className="bg-white p-4 rounded shadow">
        <span className="block text-2xl font-bold">{timeLeft.minutes}</span>
        <span className="block text-sm">minutes</span>
      </div>
      <div className="bg-white p-4 rounded shadow">
        <span className="block text-2xl font-bold">{timeLeft.seconds}</span>
        <span className="block text-sm">seconds</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
