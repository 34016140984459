import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

function Input(props) {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, dateValue, onFileSelected, ...inputProps } = props;
  const [selectedDate, setSelectedDate] = useState(dateValue);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file.name);
    if (file && onFileSelected) {
      onFileSelected(file);
    }
  };

  const handleFocused = (e) => {
    setFocused(true)
  }

  useEffect(() => {
    setSelectedDate(dateValue)
  }, [selectedDate, dateValue, props])




  let inputElement;

  switch (props?.type) {
    case 'text':
    case 'email':
    case 'password':
    case 'tel':
      inputElement = (
        <input
          {...inputProps}
          className='customInput'
          onChange={onChange}
          type={props.type}
        />
      )
      break;
    case 'textarea':
      inputElement = (
        <textarea
          className='customInput'
          onChange={onChange}
          type={props.type}
          onBlur={handleFocused}
          focused={focused.toString()}
          rows={5}
        />
      )
      break;
    case 'file':
      inputElement = (
        <div style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          <Button color="dark" onClick={handleButtonClick}>
            Upload
          </Button>
          {file && (
            <span
              style={{
                alignItems: 'center',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
              }}
            >{file}</span>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      );
      break;
    case 'select':
      inputElement = (
        <Form.Select
          aria-label="Default select"
          value={inputProps?.defaultValue}
          options={props.options}
          isDisabled={inputProps.disabled}
          onChange={(e) => {
            let foo = {
              target: {
                name: props.name,
                label: e.label,
                value: e.value
              }
            };
            onChange(foo);
          }}
        >
          <option>Open this select menu</option>
          {props.options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
        </Form.Select>
      )
      break;
    default:
      inputElement = <p>Unsupported input type</p>;
  }

  return (
    <>
      <Col md={props.size} className='form-group'>
        {label ? (
          <Form.Label
          >
            {label}
          </Form.Label>
        ) : null}
        {inputElement}
      </Col>
    </>
  )
}

export default Input;