import { createContext, useContext, useEffect, useState } from 'react';
import GlobalContext from './store';
import moment from 'moment';
import { Get, Post } from '../api/server';

const Booking = createContext({
  booking: {},
  setBooking: () => { },

  ucb: [],
  setUcb: () => { },

  user: {},
  setUser: () => { },

  openDays: [],
  setOpenDays: () => { },

  currentSlots: null,
  setCurrentSlots: () => { },

  toCoordinates: [],
  setToCoordinates: () => { },

  directions: {},
  setDirections: () => { },

  totals: {
    total: 0,
    deposit: 0,
    service: 0,
    travel: 0,
  },
  setTotals: () => { },
});

export const BookingContextProvider = ({ children }) => {
  const { company } = useContext(GlobalContext);
  const [booking, setBooking] = useState({
    service: null,
    variation: null,
    booking_price: 0,
    travel_cost: 0,
    cost4Travel: 0,
    travel: false,
  });
  const [user, setUser] = useState({});
  const [openDays, setOpenDays] = useState([]);
  const [ucb, setUcb] = useState([]);
  const [currentSlots, setCurrentSlots] = useState(null);
  const [toCoordinates, setToCoordinates] = useState([]);
  const [directions, setDirections] = useState({});
  const [totals, setTotals] = useState({
    total: 0,
    deposit: 0,
    service: 0,
    travel: 0,
  });

  async function getInitDataAgain() {
    let bookingDates = company?.booking_settings?.open_days;
    let openDates = await Get(`/bookingservices/GetFrontendOpenings/${company._id}`);
    let filterActiveDates = bookingDates.filter(d => d.active === true);
    let getOpenDays = filterActiveDates.map(d => d.day);

    setOpenDays(openDates?.openDays);
    setUcb(openDates?.ucb.map(d => {
      return {
        start: d.start_time,
        end: d.end_time
      }
    }));
    getBookingSchedule(getOpenDays);
  }

  useEffect(() => {
    async function getInitData() {
      let bookingDates = company?.booking_settings?.open_days;
      let openDates = await Get(`/bookingservices/GetFrontendOpenings/${company._id}`);
      let filterActiveDates = bookingDates.filter(d => d.active === true);
      let getOpenDays = filterActiveDates.map(d => d.day);
  
      setOpenDays(openDates?.openDays);
      setUcb(openDates?.ucb.map(d => {
        return {
          start: d.start_time,
          end: d.end_time
        }
      }));
      getBookingSchedule(getOpenDays);
    }
    if (company?._id) getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  function addDaysToDate(days) {
    var date = new Date(); // creates a new Date object for the current date and time
    date.setDate(date.getDate() + days); // adds the specified number of days
    return date;
  }

  async function GetCoordinates() {
    const res = await Post('/bookings/frontendDirections', {
      company : company?._id,
      to: `${user?.address} ${user?.city} ${user?.state} ${user?.zip}`,
      travel: booking?.travel,
    });
    console.log('res: ', res)
    setDirections(res?.directions);
    setToCoordinates(res?.toCoordinates);
    calculateCost();
    return true;
  }

  function subtractDaysToDate(days) {
    var date = new Date(); // creates a new Date object for the current date and time
    date.setDate(date.getDate() - days); // adds the specified number of days
    return date;
  }

  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed.
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getBookingSchedule = (data) => {
    let openedDates = getDates(addDaysToDate(1), moment().add(60, 'days'));
    let newOpenDates = openedDates.filter(foo => {
      return data.includes(foo.getDay())
    });

    setBooking(prevState => ({ ...prevState, booking_dates: newOpenDates.map(formatDate) }));





    // Format the dates in disabledDatesBefore
    let disabledDatesBefore = getDates(subtractDaysToDate(60), addDaysToDate(0)).map(formatDate);

    // Format the dates in disabledDatesAfter
    let disabledDatesAfter = getDates(addDaysToDate(60), addDaysToDate(120)).map(formatDate);

    const array3 = disabledDatesBefore.concat(disabledDatesAfter);
    // Filter and format weekDaysOff
    let openDays = openedDates;
    let weekDaysOff = openDays.filter(foo =>
      foo.getDay() === 1 ||
      foo.getDay() === 2 ||
      foo.getDay() === 3 ||
      foo.getDay() === 4
    ).map(formatDate);
    const array4 = weekDaysOff.concat(array3);

    setBooking(prevState => ({ ...prevState, disabled_dates: array3 }));
    let now = new Date();
    let dayOfWeek = now.getDay(); //0-6
    console.log('dayOfWeek: ', dayOfWeek);
  }

  const getDates = (startDate, endDate) => {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }

  const calculateCost = () => {
    
    if (booking?.travel) {
      let serviceCost = booking?.variation?.price;
      let travel = directions?.distanceNumber;
      let cost4Travel = (2 * Math.ceil(travel)) * 2;
      cost4Travel = cost4Travel > 50 ? cost4Travel : 50;
      let total = serviceCost + cost4Travel;
      let depositAmount = (total / 100) * 30;
      setTotals({
        total: Number(total.toFixed(2)),
        deposit: Number(depositAmount.toFixed(2)),
        service: Number(serviceCost.toFixed(2)),
        travel: Number(cost4Travel.toFixed(2)),
      });
    } else {
      let serviceCost = booking?.variation?.price;
      let depositAmount = (serviceCost / 100) * 30;
      setTotals({
        total: serviceCost,
        deposit: Number(depositAmount.toFixed(2)),
        service: serviceCost,
        travel: 0,
      });      
    }
  }

  function getAvailableTimeSlots(openDate) {
    // find the openDays object that matches the selected date
    let openDates = openDays.find(d => moment(d.start_time).format('YYYY-MM-DD') === openDate.format('YYYY-MM-DD'));
    if (!openDates) return;
    // format openDates to "2024-04-30T10:00:00 - 2024-04-30T18:00:00"
    openDates = moment(openDates.start_time).add(7, 'hours').toDate() + " - " + moment(openDates.end_time).add(7, 'hours').toDate()
    console.log('ssss: ', openDates);
    const format = 'YYYY-MM-DDTHH:mm:ss';
    let [openStart, openEnd] = openDates.split(" - ");
    openStart = moment(openStart);
    openEnd = moment(openEnd);

    const availableSlots = [];
    while (openStart < openEnd) {
      const endSlot = moment(openStart).add(booking?.variation?.duration, 'minutes');
      if (endSlot > openEnd) break;

      // Check if this slot overlaps with any bookings
      let isBooked = false;
      for (let booking of ucb) {
        const bookingStart = moment(booking.start);
        const bookingEnd = moment(booking.end);

        if (openStart < bookingEnd && endSlot > bookingStart) {
          isBooked = true;
          break;
        }
      }

      if (!isBooked) {
        availableSlots.push(openStart.format(format));
      }

      // Move to the next slot
      openStart.add(60, 'minutes');
    }

    setCurrentSlots(availableSlots);
  }

  function clearAll () {
    setBooking({
      service: null,
      variation: null,
      booking_price: 0,
      travel_cost: 0,
      cost4Travel: 0,
      travel: false,
    });
    setUser({});
    setOpenDays([]);
    setCurrentSlots(null);
    setToCoordinates([]);
    setDirections({});
    setTotals({
      total: 0,
      deposit: 0,
      service: 0,
      travel: 0,
    });
    getInitDataAgain();
  }

  return (
    <Booking.Provider value={{
      booking, setBooking,
      user, setUser,
      openDays, setOpenDays, getAvailableTimeSlots,
      currentSlots, toCoordinates,
      directions, GetCoordinates,
      calculateCost, totals, clearAll
    }}>
      {children}
    </Booking.Provider>
  )
}

export const useBooking = () => useContext(Booking)