import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useBooking } from '../../context/booking';
import Map from '../../components/Map';
import moment from 'moment';
import usdFormatter from '../../helpers/usdFormatter';
import { Link, useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/store';
import BookingApplePay from '../../components/BookingApplePay';
import stripePaymentModthod from '../../assets/images/paymentMethods.png';
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { alpha, styled, Switch } from '@mui/material';
import { pink } from '@mui/material/colors';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Post } from '../../api/server';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[100],
    '&:hover': {
      backgroundColor: alpha(pink[100], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[100],
  },
}));

function Review() {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const { setShowBooking, company } = useContext(GlobalContext);
  const [terms, setTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    booking,
    user,
    directions,
    toCoordinates,
    totals,
    clearAll,
  } = useBooking();

  useEffect(() => {
    if (!booking?.startTime || !booking?.variation) {
      navigate("/");
    }
    window.scrollTo(0, 0);



  }, [booking?.service, booking?.variation, navigate]);


  const startOver = () => {
    clearAll();
    setShowBooking(true);
    navigate("/");
  }

  const cardElementStyle = {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
      lineHeight: '25px',
    },
    invalid: {
      color: '#9e2146',
    },
  };

  const payBill = async (e) => {
    e.preventDefault();
    if (!terms) return toast.warning('You have NOT been charged! Please agree to the Terms and Conditions before proceeding');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
        phone: user?.phone,
      },
    });

    if (error) {
      setIsLoading(false);
      console.log('[error]', error);
      toast.error(error.message);
      return;
    }

    console.log('totals', totals);

    Swal.fire({
      title: "Processing Transaction",
      html: "Please wait while we process your payment",
      background: "black",
      color: "white",
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: () => {
        Swal.showLoading();
        Post(`/bookings/frontend/createBooking`, {
          token: paymentMethod,
          company: company._id,
          date: moment(booking?.startTime).format('YYYY-MM-DD'),
          startTime: moment(booking?.startTime).format('HH:mm'),
          service: booking?.service?._id,
          variation: booking?.variation?._id,
          email: user?.email,
          phone: user?.phone,
          first_name: user?.first_name,
          last_name: user?.last_name,
          address: user?.address,
          address2: user?.address2,
          city: user?.city,
          state: user?.state,
          zip: user?.zip,
          travel: booking?.travel,
          totals : totals
        }).then((response) => {
          Swal.close();
          setIsLoading(false);
          toast.success('Appointment has been successfully booked');
          navigate(`/booking/confirmation/${response._id}`)
        }).fail((error) => {
          console.log(error)
          Swal.close();
          setIsLoading(false);
          toast.error(error?.responseJSON?.payment_intent?.last_payment_error?.message || error?.responseJSON || 'Something went wrong on our end. Booking has not been purchased. Please try again or contact Viki by email.');
        });
      },
    });

  }

  return (
    <div className="booking mb-10">
      <div style={{
        backgroundColor: 'white',
        width: '100%',
        height: 100,
      }}>

      </div>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          <center>
            <div className='nanum-myeongjo-extrabold open-sans' style={{ fontSize: 40, height: 100, marginTop: 20, fontWeight: 500 }}>Review Booking</div>
          </center>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          <Row>
            <Col sm={12}>
              <div className='service-Container border p-2'>
                <img src={booking?.service?.cover} alt={booking?.service?.name} />
                <div className='service-info-Container'>
                  <div style={{ fontSize: 18, fontWeight: 700 }}>{booking?.service?.name}</div>
                  <div>{booking?.variation?.name}</div>
                  <div style={{ fontSize: 12 }}>{moment(booking?.startTime).format('lll')}</div>
                </div>
                <div className='service-price'>{usdFormatter(booking?.variation?.price)}</div>
              </div>

              {booking?.travel && (
                <div className='service-Container border p-2 mt-3'>
                  <img style={{ height: 70 }} src={require('../../assets/images/travel2.jpg')} alt={booking?.service?.name} />
                  <div className='service-info-Container'>
                    <div style={{ fontSize: 18, fontWeight: 700 }}>Travel Cost</div>
                    <div style={{ fontSize: 14 }}>{user?.city}, {user?.state}</div>
                    <div style={{ fontSize: 12 }}>Traveling {directions?.distanceNumber * 2} miles</div>
                  </div>
                  <div className='service-price'>{usdFormatter(totals?.travel)}</div>
                </div>
              )}
              <div className='service-Container border p-2 mt-3'>
                <img style={{ height: 70 }} src={require('../../assets/images/deposit.jpg')} alt={booking?.service?.name} />
                <div className='service-info-Container'>
                  <div style={{ fontSize: 18, fontWeight: 700 }}>Deposit</div>
                  <div style={{ fontSize: 12 }}>30% OF TOTAL</div>
                </div>
                <div className='service-price'>{usdFormatter(totals?.deposit)}</div>
              </div>

              <div style={{ padding: 10 }} className='service-Container mt-3'>
                <div className='service-info-Container'>
                  <div style={{ fontSize: 26, fontWeight: 500 }}>Total</div>
                </div>
                <div className='service-price'>{usdFormatter(totals?.total)}</div>
              </div>

              <div style={{ padding: 10 }} className='service-Container mt-1'>
                <div className='service-info-Container'>
                  <div style={{ fontSize: 26, fontWeight: 800 }}>Due Today</div>
                </div>
                <div style={{ fontWeight: 800 }} className='service-price'>{usdFormatter(totals?.deposit)}</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-4 mb-4">
                <PinkSwitch
                  required
                  size="small"
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                /><span className='agree-switch-text'> I agree to the <Link to='/terms' target='_blank'>Terms and Conditions</Link></span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className='center'>
              <BookingApplePay 
                totalAmountDue={totals?.deposit}
                booking={booking}
                user={user}
                totals={totals}
                terms={terms}
                history={navigate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="separator">
                <span className="line"></span>
                <span className="text">Enter card manually</span>
                <span className="line"></span>
              </div>
            </Col>
          </Row>

          <Row>
            {true && (
              <>
                <Col md={12}>
                  <div className="card-element-container">
                    <Form.Control
                      as={CardElement}
                      options={{ style: cardElementStyle }}
                    />
                  </div>
                </Col>

                <Col className='text-center mb-2' md={12}>
                  <div className='text-center'>
                    <p>
                      <a href='https://stripe.com/legal/ssa' target='_blank' rel='noreferrer' className='flex flex-col items-center'>
                        <img style={{ width: 130, height: 'auto' }} className='w-5 mt-3' src={stripePaymentModthod} alt="methods" />
                        <span>Stripe Terms and Conditions</span>
                      </a>
                    </p>
                  </div>
                  <div onClick={payBill} class="myButn pointer mb-1 mt-4" style={{ backgroundColor: 'black' }}>Book Now</div>
                </Col>
              </>
            )}
            <Col sm={12} className='center mb-10'>
              <div onClick={startOver} class="myButn pointer mb-1">Restart Booking</div>
            </Col>
          </Row>

        </Col>
        <Col style={{ marginBottom: 60 }} md={3}>
          <div style={{ height: 585, overflow: 'hidden', borderRadius: 8 }}>
            <Map
              zoom={booking?.travel ? 14 : 8}
              coords={booking?.travel ? toCoordinates : []}
              hasMarker={booking?.travel ? true : false}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Review