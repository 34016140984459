import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet';

const MapCenter = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
    map.setZoom(zoom)
  }, [center, map, zoom]);

  return null;
};

export default function Map({ zoom, coords=[], hasMarker = true }) {
  const [center, setCenter] = useState([-122.398917,37.782147].reverse());
  useEffect(() => {
    if (coords.length < 2)return;

    setCenter([
      coords[1], // Latitude
      coords[0]  // Longitude
    ]);

  }, [coords, zoom, hasMarker]);
    

  const iconPerson = new L.Icon({
    iconUrl: require('../assets/images/markers/6.png'),
    shadowUrl: require('../assets/images/shadow.png'),
    iconSize: [40, 80], // size of the icon
    shadowSize: [40, 80], // size of the shadow
    iconAnchor: [10, 78], // point of the icon which will correspond to marker's location
    shadowAnchor: [6, 80],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup shouåld open relative to the iconAnchor
  });

  return (
    <div className="map" style={{ height: '100%', width: '100%', zIndex: 0 }}>
      <MapContainer
        id='mapid'
        center={center}
        zoom={zoom || 12}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        doubleClickZoom={false}
        touchZoom={false}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://Liveonbeat.com">Live On Beat</a>'
          url="https://api.mapbox.com/styles/v1/djstewie/ckkpf1ldb15gq17qk2lmqmvhc/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGpzdGV3aWUiLCJhIjoiY2trcGQ2MW84MDZhNjJ5bzVrbm8xemhycSJ9.y592_-MfDaduUdSMbT66iQ"
        />
        {hasMarker && (
          <Marker position={center} icon={iconPerson}>
            {/* <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup> */}
          </Marker>
        )}
        <MapCenter center={center} zoom={zoom} />
      </MapContainer>
    </div>
  )
}
