import React from 'react'

function PageHeader({image, title, details}) {
  return (
    <div class="yscott-about-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12"> <img src={image} class="img-responsive" alt="" /> </div>
          <div class="col-md-12 col-sm-12 ">
            <div class="yscott-caption">
              <h2
                style={{
                  marginTop: 35,
                  fontSize: '3rem',
                }}
              >{ title }</h2>
              <p>{ details }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHeader