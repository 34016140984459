import React, { useEffect, useRef } from 'react';
import Litepicker from 'litepicker';
import { useBooking } from '../context/booking';
import moment from 'moment';

const LitePicker = ({onSelect}) => {
  const { booking, openDays, getAvailableTimeSlots } = useBooking();
  const pickerRef = useRef(null);
  const litepickerRef = useRef(null); // Ref to store the Litepicker instance

  useEffect(() => {
    // Destroy the previous instance if it exists
    if (litepickerRef.current) {
      litepickerRef.current.destroy();
    }

    console.log('disabled_dates: ', booking?.disabled_dates);

    // Create a new instance of Litepicker
    litepickerRef.current = new Litepicker({ 
      element: pickerRef.current,
      inlineMode: true,
      singleMode: true,
      
      lockDaysFilter: (date) => {
          // Return true to lock the day, except for highlightedDays
          const formattedDate = date.format('YYYY-MM-DD');
          return !openDays.map(d => moment(d?.start_time).format('YYYY-MM-DD')).includes(formattedDate);
        },
        highlightedDays: openDays.map(d => moment(d?.start_time).toDate()),
  
      // lockDays: booking?.disabled_dates,
      // lockDaysFilter: (date1, date2, pickedDates) => {
      //   return !booking?.booking_dates.includes(date1.format('YYYY-MM-DD'));
      // },
      // onSelect: onDateSelect,
      setup: (picker) => {
        picker.on('selected', (date1, date2) => {
          console.log('date1: ', date1);
          console.log('date2: ', date2);
         onSelect(date1)
         getAvailableTimeSlots(date1);
        });
      },
    });

    // Cleanup function to destroy Litepicker instance when component unmounts
    return () => {
      if (litepickerRef.current) {
        litepickerRef.current.destroy();
      }
    };
  }, [booking?.booking_dates, booking?.disabled_dates, onSelect]); // Dependency array

  return (
    <input 
      type="text" 
      ref={pickerRef} 
      style={{ display: 'none' }} 
      name="Booking Date" 
      disabled='disabled'
    />
  );
};

export default LitePicker;
