import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import GlobalContext from '../context/store';
import { useBooking } from '../context/booking';
import { MdKeyboardBackspace } from "react-icons/md";
import STORE from '../assets/images/store.png';
import TRAVEL from '../assets/images/travel.jpg';
import Input from './Input';
import LitePicker from './LitePicker';
import Title from './Title';
import moment from 'moment';
import Map from './Map';
import { useNavigate } from 'react-router-dom';
import parseFullName from '../helpers/parseFullName';
import { toast } from 'react-toastify';

const setfullName = (name, setUser) => {
  setUser(prevState => ({ ...prevState, ...parseFullName(name) }));
}

const RenderUserInformation = ({ setUser, booking, user }) => (
  <>
    <center>
      <h3>Contact Information</h3>
    </center>
    <div className="row">

      <div className="col-md-12 form-group">
        <Input onChange={(e) => {
          setfullName(e.target.value, setUser);
        }} name="name" type="text" placeholder="Full Name *" value={user?.name} />
      </div>

      <div className="col-md-6 form-group">
        <Input onChange={(e) => {
          setUser(prevState => ({ ...prevState, phone: e.target.value }));
        }} name="phone" type="text" placeholder="Phone *" value={user?.phone} />
      </div>

      <div className="col-md-6 form-group">
        <Input onChange={(e) => {
          setUser(prevState => ({ ...prevState, email: e.target.value }));
        }} name="email" type="email" placeholder="Email *" value={user?.email} />
      </div>

      {booking?.travel && (
        <>
          <div className="col-md-8 form-group">
            <Input onChange={(e) => {
              setUser(prevState => ({ ...prevState, address: e.target.value }));
            }} name="address" type="text" placeholder="Address *" value={user?.address} />
          </div>

          <div className="col-md-4 form-group">
            <Input onChange={(e) => {
              setUser(prevState => ({ ...prevState, address2: e.target.value }));
            }} name="address2" type="text" placeholder="Unit, Apt" value={user?.address2} />
          </div>

          <div className="col-md-4 form-group">
            <Input onChange={(e) => {
              setUser(prevState => ({ ...prevState, city: e.target.value }));
            }} name="city" type="text" placeholder="City *" value={user?.city} />
          </div>

          <div style={{ marginTop: 15 }} className="col-md-4 form-group">
            <select onChange={(e) => {
              setUser(prevState => ({ ...prevState, state: e.target.value }));
            }} id="userState" name="state" className="margin-bottom-20" style={{ color: '#a0a7b3' }} value={user?.state}>
              <option>Select State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div className="col-md-4 form-group">
            <Input onChange={(e) => {
              setUser(prevState => ({ ...prevState, zip: e.target.value }));
            }} name="zip" type="text" placeholder="Zip Code *" value={user?.zip} />
          </div>
        </>
      )}
    </div>
  </>
)


function Booking(props) {
  const navigate = useNavigate();
  const { services, setShowBooking } = useContext(GlobalContext);
  const { booking, setBooking, setUser, user, currentSlots, GetCoordinates, directions, toCoordinates, calculateCost } = useBooking();
  const [subService, setsubService] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [isSubService, setisSubService] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTravel, setSelectedTravel] = useState('Studio');
  const [nextButton, setNextButton] = useState('Next');

  useEffect(() => {
    async function getServices() {
      setsubService(services);
    }
    getServices();
  }, [services]);



  // const nextTab = () => {
  //   setCurrentTab(currentTab + 1);
  // };



  function validateTab1() {
    // Assuming tab 1 requires a selected service and text input
    //booking.service is an object, so we can check if it exists and booking.variation
    return booking?.service && booking?.variation
  }

  function validateTab2() {
    // Assuming tab 2 requires a sub-service to be selected
    return true;
  }

  function validateTab3() {
    let foo = true;

    if (!user?.first_name || !user?.phone || !user?.email) {
      foo = false;
    }

    if (booking?.travel) {
      if (!user?.address || !user?.city || !user?.state || !user?.zip) {
        foo = false;
      } else {
        GetCoordinates()
      }
    }
    // Assuming tab 2 requires a sub-service to be selected
    calculateCost();
    setNextButton('Review')
    return foo;
  }

  function validateTab4() {
    if (!booking?.startTime) return false;
    calculateCost();
    navigate("/booking/review");
    setShowBooking(false);
    return true;
  }



  // Add more validation functions as needed for additional tabs

  // Step 2: Modify the nextTab function to use these validations
  const nextTab = () => {
    let canProceed = false;

    switch (currentTab) {
      case 1:
        canProceed = validateTab1();
        break;
      case 2:
        canProceed = validateTab2();
        break;
      case 3:
        canProceed = validateTab3();
        break;
      case 4:
        canProceed = validateTab4();
        break;
      // Add cases for additional tabs as needed
      default:
        canProceed = true; // No validation for tabs without specific requirements
    }

    if (canProceed) {
      setCurrentTab(currentTab + 1);
    } else {
      toast.warning("Please fill out or select all fields for the current step before proceeding.");
    }
  };




  const prvTab = () => {
    setCurrentTab(currentTab - 1);
  }




  const PickService = () => (
    <>
      {isSubService && <MdKeyboardBackspace size={35} onClick={() => setisSubService(false)} />}
      <center>
        <h3 className="">Pick a Service</h3>
      </center>
      <Row>
        {!isSubService ? (
          <>
            {services.length > 0 && services.map((service, index) => (
              <Col md={4} key={index}>
                <label>
                  <input onClick={() => {
                    if (service?.variations.length > 1) {
                      setisSubService(true);
                      setsubService(service?.variations);
                      setBooking({ ...booking, service: service, currentBookingSelection: service })
                    } else {
                      setBooking({ ...booking, service: service });
                      setBooking({ ...booking, variation: service?.variations[0] });
                    }
                    setSelectedService(service); // Update the selected service
                  }} type="radio" name="service" value="small" />
                  <img className={`${selectedService === service ? 'border-2 border-red-500' : 'grayscale'}`} src={service.cover} alt='' />
                </label>
                <div className="service-radio-title">{service.name} - ${service?.price ? service?.price : service?.variations[0]?.price}</div>
              </Col>
            ))}
          </>
        ) : (
          <>
            {subService.length > 0 && subService.map((service, index) => (
              <Col md={4} key={index + 6}>
                <label>
                  <input
                    onClick={() => {
                      setSelectedService(service); // Update the selected service
                      setBooking({ ...booking, variation: service });
                    }}
                    type="radio"
                    name="service"
                    value={service.id} // Use a unique identifier for each service
                    className="hidden" // Hide the default radio button
                  />
                  <img
                    className={`${selectedService === service ? 'border-2 border-red-500' : 'grayscale'}`}
                    src={booking?.currentBookingSelection?.cover}
                    alt=''
                  />
                </label>
                <div className="service-radio-title">
                  {service.name} - ${service?.price ? service?.price : service?.variations[0]?.price}
                </div>
              </Col>
            ))}
          </>
        )}

      </Row>
      <Row>
        <Col sm={12}>

        </Col>
      </Row>
    </>
  )

  const RenderTravel = () => (
    <>
      <center>
        <h3>Travel</h3>
      </center>
      <form>
        <div className="row center-cal">
          <div className="col-md-4 offset-md-2">
            <label>
              <input type="radio" name="store" value="small2" onClick={() => {
                setBooking(prevState => ({ ...prevState, travel: false }));
                setSelectedTravel('Studio');
              }} checked={booking?.travel} />
              <img className={`${selectedTravel === 'Studio' ? 'border-2 border-red-500' : 'grayscale'}`} src={STORE} alt='' />
            </label>
            <div className="service-radio-title">In Studio</div>
          </div>
          <div className="col-md-4">
            <label>
              <input onClick={() => {
                setBooking(prevState => ({ ...prevState, travel: true }));
                setSelectedTravel('Travel');
              }} type="radio" name="store" value="small" checked={booking?.travel} />
              <img className={`${selectedTravel === 'Travel' ? 'border-2 border-red-500' : 'grayscale'}`} src={TRAVEL} alt='' />
            </label>
            <div className="service-radio-title">Travel</div>
          </div>
        </div>
      </form>
    </>
  )

  const Review = () => (
    <>
      <Row>
        <center>
          <h3>Review</h3>
        </center>
      </Row>
      <Row>
        <Col md={6}>
          <Row>
            <Col sm={12}>
              <h4>Service</h4>
              <p>{booking?.service?.name}</p>
              <p>{booking?.variation?.name}</p>
            </Col>
            <Col sm={12}>
              <h4>Travel</h4>
              <p>{booking?.travel ? 'Address: ' + user?.address : ''}</p>
              <p>{booking?.travel ? 'City: ' + user?.city : ''}</p>
              <p>{booking?.travel ? 'State: ' + user?.state : ''}</p>
              <p>{booking?.travel ? 'Zip: ' + user?.zip : ''}</p>
              <p>{booking?.travel ? 'Miles: ' + directions?.distance : ''}</p>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <div style={{ height: 300, overflow: 'hidden', borderRadius: 5 }}>
            <Map coords={toCoordinates} />
          </div>

        </Col>
      </Row>
    </>

  )

  const onSelect = (date) => {
    setBooking(prevState => ({ ...prevState, date_times: [] }));
    // wait 2 seconds before updating the state

    date = date.toJSDate();
    setUser(prevState => ({ ...prevState, booking_date: date }));

    let x = {
      slotInterval: user?.service?.duration || 45,
      openTime: '07:00',
      closeTime: '17:00'
    };

    //Format the time
    let startTime = moment(x.openTime, "h:mm");

    //Format the end time and the next day to it 
    let endTime = moment(x.closeTime, "h:mm").add(1, 'hours');

    //Times
    let allTimes = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("h:mma"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    setBooking(prevState => ({ ...prevState, date_times: allTimes }));
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Book Appointment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {currentTab === 1 && <PickService />}
        {currentTab === 2 && <RenderTravel />}
        {currentTab === 3 && <RenderUserInformation user={user} booking={booking} setUser={setUser} />}
        {currentTab === 4 && (
          <Row>
            <Col sm={6}>
              <div className="booking-litePicker">
                <LitePicker onSelect={onSelect} />
              </div>

            </Col>
            <Col sm={6}>
            <div
            className='open-sans'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 15,
              fontSize: 20,
              fontWeight: '400'
            }}>
              {user?.booking_date ? moment(user?.booking_date).format('ddd MMM, Do YYYY') : 'Select Date'}
            </div>
              <Row>
                {Array.isArray(currentSlots) && (
                  <>
                    {currentSlots.length > 0 ? currentSlots.map((time, index) => (
                      <Col key={index} sm={4}>
                        <label style={{ display: 'block' }}>
                          <input type="radio" name="startTime" value={time} onChange={(e) => setBooking({ ...booking, startTime: e.target.value })} />
                          <div class="time-date">{moment(time).format('h:mm A')}</div>
                        </label>
                      </Col>
                    )) : (
                      <div style={{
                        // center
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 100,
                        // marginLeft: -45,
                        color: '#a0a7b3',
                        fontSize: 20
                      }}>
                        No available slots at this time.
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        )}
        <div style={{ overflow: 'auto' }}>
          <div className="mt-10">
            {currentTab > 1 && <button type="button" onClick={prvTab} id="prevBtn" className="pull-left">Previous</button>}

            {currentTab < 5 && <button type="button" onClick={nextTab} id="nextBtn" className="pull-right bg-[#556a89]">{nextButton}</button>}
          </div>
        </div>
      </Modal.Body>

    </Modal>
  )
}

export default Booking