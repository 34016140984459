import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import GlobalContext from '../context/store';
import { Post } from '../api/server';
import isValidEmail from '../helpers/isValidEmail';

export default function Subscribe() {
  const { company } = useContext(GlobalContext);
  const [focused, setFocused] = useState(false);

  const [values, setValues] = useState({
    email: '',
  });

  const resetForm = () => {
    setValues({
      email: '',
    });
  }

  const handleFocused = (e) => {
    setFocused(true)
  }

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!values?.email) return notify('Please type in your email account', 'warning');
    if (!isValidEmail(values?.email)) return notify('Please type in a valid email address', 'warning');
    Post('/users', {
      email: values.email,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Thank you for subscribing!', 'success');
    }).fail(err => {
      resetForm();
      notify(err.responseJSON, 'error');
    });
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <h3>Subscribe</h3>
      <p>
        <div style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>
          <input
            className="subscribe-input"
            type="email"
            name="email"
            placeholder="Your Email..."
            onBlur={handleFocused}
            focused={focused.toString()}
            onChange={onChange}
            required
            value={values['email']}
          />
          <button onClick={handleSubmit} className="subscribe-button">Subscribe</button>
        </div>
      </p>
    </>
  )
}
